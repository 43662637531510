<template>
	<Loader v-if="isLoad" />
</template>

<script>
import Loader from '@/components/Loader.vue';
import { mapMutations } from 'vuex';
const axios = require('@/store/config/axios.config.js').default;
export default {
	components: {
		Loader,
	},
	data() {
		return {
			isLoad: true,
		};
	},
	methods: {
		...mapMutations(['onload', 'outload']),
		checkParameters() {
			const key = this.$route.query.k;
			const token = this.$route.query.t;
			const id = this.$route.query.id;
			if (!key || !token || !id) {
				// this.$router.push('/');
			} else {
				axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
				this.$store.commit('auth/LOGIN_SUCCESS', {
					userID: id,
					token: key,
					Bearer: token,
					MainBalance: 0,
					TradeBalance: 0,
					TradeDemoBalance: 0,
				});
				this.$router.push({ name: 'dashboard' });
			}
		},
	},
	created() {
		this.checkParameters();
	},
};
</script>

<style>
</style>