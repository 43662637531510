<template>
	<div class="privateDashboard py-3 pt-5">
		<b-container fluid="xl">
			<div class="history d-block d-lg-flex flex-wrap">
				<div class="flex-fill">
					<h3 class="mb-3">User Detail (USDT)</h3>
				</div>
				<download-excel
					class="btn btn-aktrade ml-3"
					:data="Statistical.list"
					:fields="json_fields"
					:header="[
						'Total deposit: ' + Statistical.total.deposit,
						'Total withdraw: ' + Statistical.total.withdraw,
						'Total deposit to game: ' + Statistical.total.deposit_to_game,
						'Total withdraw from game: ' + Statistical.total.withdraw_from_game,
						'Total transfer to: ' + Statistical.total.transfer_to,
						'Total transfer from: ' + Statistical.total.transfer_from,
						'Total live balance: ' + Statistical.total.live,
						'Total main balance: ' + Statistical.total.main,
					]"
					type="xls"
					:name="`user-${now}.xls`"
				>
					Export
				</download-excel>
			</div>
			<div class="tableWrapper table-responsive">
				<b-table
					thead-class="thead-ig"
					:striped="true"
					:bordered="true"
					:hover="true"
					:items="Statistical.list"
					:fields="fields"
					show-empty
				>
					<template #thead-top="">
						<b-tr>
							<b-th colspan="2"></b-th>
							<b-th>Total Deposit</b-th>
							<b-th>Total Withdraw</b-th>
							<b-th>Total Deposit To Game</b-th>
							<b-th>Total Withdraw From Game</b-th>
							<b-th>Total Transfer To</b-th>
							<b-th>Total Transfer From</b-th>
							<b-th>Total Main</b-th>
							<b-th>Total Live Balance</b-th>
						</b-tr>
						<b-tr>
							<b-th colspan="2"></b-th>
							<b-th class="text-right">{{ Statistical.total.deposit }} </b-th>
							<b-th class="text-right">{{ Statistical.total.withdraw }} </b-th>
							<b-th class="text-right"
								>{{ Statistical.total.deposit_to_game }}
							</b-th>
							<b-th class="text-right"
								>{{ Statistical.total.withdraw_from_game }}
							</b-th>
							<b-th class="text-right"
								>{{ Statistical.total.transfer_to }}
							</b-th>
							<b-th class="text-right">{{
								Statistical.total.transfer_from
							}}</b-th>
							<b-th class="text-right">{{ Statistical.total.main }}</b-th>
							<b-th class="text-right">{{ Statistical.total.live }}</b-th>
						</b-tr>
					</template>
					<template #empty="">
						<h5 class="text-center p-2">
							{{ $t('support.no_data_available_in_table') }}
						</h5>
					</template>
					<template #cell(Deposit)="data">
						<p class="text-right mb-0">
							{{ TruncateToDecimals(data.item.Deposit) }}
						</p>
					</template>
					<template #cell(Withdraw)="data">
						<p class="text-right mb-0">
							{{ TruncateToDecimals(data.item.Withdraw) }}
						</p>
					</template>
					<template #cell(DepositToGame)="data">
						<p class="text-right mb-0">
							{{ TruncateToDecimals(data.item.DepositToGame) }}
						</p>
					</template>
					<template #cell(WithdrawFromGame)="data">
						<p class="text-right mb-0">
							{{ TruncateToDecimals(data.item.WithdrawFromGame) }}
						</p>
					</template>
					<template #cell(TransferTo)="data">
						<p class="text-right mb-0">
							{{ TruncateToDecimals(data.item.TransferTo) }}
						</p>
					</template>
					<template #cell(TransferFrom)="data">
						<p class="text-right mb-0">
							{{ TruncateToDecimals(data.item.TransferFrom) }}
						</p>
					</template>
					<template #cell(main)="data">
						<p class="text-right mb-0">
							{{ TruncateToDecimals(data.item.main) }}
						</p>
					</template>
					<template #cell(trade)="data">
						<p class="text-right mb-0">
							{{ TruncateToDecimals(data.item.trade) }}
						</p>
					</template>
				</b-table>
			</div>
			<!-- END HISTORY -->
		</b-container>
	</div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
export default {
	data() {
		return {
			now: moment().format('DD-MM-YYYY'),
			currentPage: 1,
			json_fields: {
				'User Email': 'email',
				'User ID': 'userId',
				'Deposit (USDT)': 'Deposit',
				'Withdraw (USDT)': 'Withdraw',
				'Deposit To Game (USDT)': 'DepositToGame',
				'Withdraw Fro mGame (USDT)': 'WithdrawFromGame',
				'Transfer To': 'TransferTo',
				'Transfer From': 'TransferFrom',
				'Main Balance': 'main',
				'Trade Balance': 'trade',
			},
			fields: [
				{
					key: 'userId',
					label: 'User ID',
					sortable: true,
					class: 'text-center',
				},
				{
					key: 'email',
					label: 'User Email',
					sortable: true,
					class: 'text-center text-lowercase',
				},
				{
					key: 'Deposit',
					label: 'Deposit',
					sortable: true,
					class: 'text-center',
				},

				{
					key: 'Withdraw',
					label: 'Withdraw',
					sortable: true,
					class: 'text-center',
				},
				{
					key: 'DepositToGame',
					label: 'Deposit To Game',
					sortable: true,
					class: 'text-center',
				},
				{
					key: 'WithdrawFromGame',
					label: 'Withdraw From Game',
					sortable: true,
					class: 'text-center',
				},
				{
					key: 'TransferTo',
					label: 'Transfer To',
					sortable: true,
					class: 'text-center',
				},
				{
					key: 'TransferFrom',
					label: 'Transfer From',
					sortable: true,
					class: 'text-center',
				},
				{
					key: 'main',
					label: 'Main Balance',
					sortable: true,
					class: 'text-center',
				},
				{
					key: 'trade',
					label: 'Live Balance',
					sortable: true,
					class: 'text-center',
				},
			],
		};
	},
	computed: {
		...mapGetters({
			Statistical: 'statistical/StatisticalMoney',
		}),
	},
	methods: {
		...mapMutations(['onload', 'outload']),
		search() {
			this.currentPage = 1;
			this.$store.dispatch('statistical/req_getStatisticsMoney', {
				from: this.from,
				to: this.to,
			});
		},

		paginate(page) {
			if (page > 0 && page <= this.Statistical.total) {
				this.currentPage = page;
				this.$store.dispatch('statistical/req_getStatisticsMoney', {
					from: this.from,
					to: this.to,
					page: page,
				});
			}
		},
		TruncateToDecimals(num, mah, dec = 2) {
			var calcDec = Math.pow(10, dec);
			var newNum = Math.trunc(num * calcDec) / calcDec;
			const d = newNum.toString().split('.')[1];
			const precision = d ? (d.length > 2 ? 2 : d.length) : 0;
			return `${mah ? mah : ''}${newNum
				.toFixed(precision)
				.replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;
		},
	},
	created() {
		this.$store.dispatch('statistical/req_getStatisticsMoney');
	},
	watch: {
		Statistics: {
			handler(nVal) {
				if (nVal) {
					this.Sections();
				}
			},
		},
	},
	async mounted() {
		// simulate load data
		this.onload();
		await new Promise((resolve) => setTimeout(resolve, 300));
		this.outload();
	},
};
</script>
<style>
.card-dashboard {
	min-height: 165px;
	align-items: center;
}

.customer-date-picker.form-control label.form-control.form-control-sm {
	color: #fff !important;
	display: flex;
	justify-content: center;
	align-items: center;
}
@media (max-width: 480px) {
	.customer-date-picker.form-control label.form-control.form-control-sm {
		font-size: 11px;
	}
	.b-form-btn-label-control.form-control {
		position: unset;
	}
}

.customer-date-picker.form-control .dropdown-menu .form-control {
	background: #ffffffa8;
}
</style>
<style lang="scss" scoped>
/*** IMPORTS ***/
@import '~@/assets/scss/mixins.scss';
@import '~@/assets/scss/colors.scss';

.red {
	color: red;
}
.green {
	color: green;
}
/*** SCROLL BAR ***/
/* width */
::-webkit-scrollbar {
	width: 3px;
	height: 3px;
}
.fz-16 {
	font-size: 16px;
}
/* Track */
::-webkit-scrollbar-track {
	background: #000;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 3px;
}
.btn-none {
	padding: 3px 10px;
	background: #6abe43;
	border: none;
	color: #fff;
	font-size: 14px;
	margin: 0 5px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

@media (min-width: 768px) {
	.privateDashboard {
		background-image: url('~@/assets/images/bg_pc.jpg');
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		min-height: 100vh;
	}
}
@media (max-width: 768px) {
	.privateDashboard {
		background-image: url('~@/assets/images/bg_mobile.jpg');
		background-size: cover;
		background-position: center center;
		background-repeat: no-repeat;
		background-attachment: fixed;
		min-height: 100vh;
	}
}
h3,
.h3 {
	font-size: 1.5rem;
}
.box {
	min-height: 250px;
	background: rgb(35 31 32 / 0.6) !important;
	margin: 0 2px;
	border: 1px solid #fff200;
	width: 100%;
	padding: 20px;
	position: relative;
	margin: auto;
	border-radius: 15px;
}

/* CHART STATS */
.chartWrapper {
	.chart {
		width: 150px;
		margin: 0 auto;

		.name,
		.value {
			font-weight: bold;
			font-size: 1.1em;
		}

		.value {
			font-size: 2em;
		}
	}
}
/* END CHART STATS */

/* TRADE STATS */
.tradeStats {
	.description {
		margin: 10px 0;
		display: flex;

		.icon {
			width: 24px;
			height: 24px;
			border: 6px solid $primaryColor;
			margin-right: 10px;
			border-radius: 50%;
		}
	}

	.metrics > :first-child {
		border-right: 1px solid white;
	}

	.metrics {
		text-align: center;
		margin-top: 15px;

		.name {
			font-weight: bold;
		}

		.value {
			font-size: 1.3em;
			font-weight: bold;
		}
	}
}
/* END TRADE STATS */

/* OTHER METRICS */
.otherMetric {
	.box {
		padding: 40px;
		max-width: 500px;
		min-height: 150px;

		.icon {
			width: 42px;
			height: 42px;
			margin-right: 20px;
		}

		.name {
			font-weight: bold;
			font-size: 1em;
			line-height: 1em;
		}

		.value {
			font-weight: bold;
			font-size: 1.5em;
		}
	}
}
@media (min-width: 768px) {
	.div-summary {
		height: calc(100% - 150px);
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
}
/* END OTHER METRICS */

/* HISTORY */
.history {
	.toolBar {
		display: flex;
		.input-date {
			height: 35px;
			align-items: center;
			border-radius: 30px;
			background: transparent;
			align-self: center;
			margin-right: 10px;
			display: flex;
		}

		input {
			background-color: transparent;
			border: none;
			color: white;
			min-width: 10px;
			width: calc(50% - 40px);
			font-size: 0.8em;
		}

		button {
			font-size: 0.8em;
			font-weight: bold;
			min-width: 80px;
			border-radius: 5px 5px 0 0;
		}
	}
}

/* END HISTORY */

/* TABLE */
.tableWrapper {
	width: 100%;

	.table {
		width: 100%;
		font-size: 1.1em;
		background-color: rgb(35 31 32 / 0.6);
		border: 1px solid #fff200;

		thead {
			background: linear-gradient(90deg, #f4eb25, #f4c41b) !important;

			th {
				color: black !important;
			}
		}

		th,
		td {
			border: none;
			padding: 10px 5px;
			text-overflow: ellipsis;
			overflow: hidden;
			text-align: center;
			border: 1px solid #ccc !important;
			background: #fff0 !important;
			color: #fff;
		}

		th {
			font-weight: bold;
			font-size: 1em;
		}
	}
}
/* END TABLE */

/*** RESPONSIVE ON PC ***/
@media (min-width: 992px) {
	.privateDashboard {
		.tableWrapper {
			.table {
				font-size: 1em;
			}
		}
	}

	/* HISTORY */
	.history {
		.toolBar {
			input {
				font-size: 1em;
				width: auto;
			}

			button {
				font-size: 1em;
			}
		}
	}
	/* END HISTORY */
}
.paginate {
	display: flex;
	justify-content: space-around;
	height: 50px;
	align-items: center;

	.button-div {
		flex: 0 0 300px;
		display: flex;
		justify-content: space-evenly;
	}
}
@media (min-width: 1600px) {
	.container-xl {
		max-width: 1580px;
	}
}
</style>